<template>
  <img v-if="imageSrc" :src="imageSrc" :alt="alt" />
</template>

<script setup>
import { ref, defineProps } from 'vue';

const props = defineProps({
  src: {
    type: String,
    required: true,
    description: 'Image path without "compressed" directory and file extension',
  },
  transparent: {
    type: Boolean,
    default: false,
    description: 'Flag indicating if the image has transparency',
  },
  alt: {
    type: String,
    default: '',
    description: 'Alternate text for the image',
  },
});

const imageSrc = ref('');

selectOptimalImage();

async function selectOptimalImage() {
  // Base path to the compressed images
  const basePath = `/img/compressed/${props.src}`;
  const format = await getOptimalFormat();
  imageSrc.value = `${basePath}.${format}`;
}

async function getOptimalFormat() {
  const [avifSupported, webpSupported] = await Promise.all([
    checkAVIFSupport(),
    checkWebPSupport(),
  ]);

  if (avifSupported) {
    return 'avif';
  }
  if (webpSupported) {
    return 'webp';
  }

  // Use PNG for transparent images; otherwise, use JPEG
  return props.transparent ? 'png' : 'jpg';
}

function checkAVIFSupport() {
  if (window.avifSupportPromise) {
    return window.avifSupportPromise;
  }

  window.avifSupportPromise = new Promise(resolve => {
    if (window.supportsAVIF !== undefined) {
      // Use cached value
      resolve(window.supportsAVIF);
      return;
    }

    const img = new Image();
    img.onload = () => {
      window.supportsAVIF = true;
      resolve(true);
    };
    img.onerror = () => {
      window.supportsAVIF = false;
      resolve(false);
    };
    // Small AVIF image data URI
    img.src =
      'data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAG1pZjFhdmlmAAACAGF2MDEAAAAAZW1kYQAAAAEzZGVzYwAAABNwaWN0AAAAAAAAAAEAAAAAAAAAAQAAAAAAAAABAAAAAQ==';
  });

  return window.avifSupportPromise;
}

function checkWebPSupport() {
  if (window.webpSupportPromise) {
    return window.webpSupportPromise;
  }

  window.webpSupportPromise = new Promise(resolve => {
    if (window.supportsWebP !== undefined) {
      // Use cached value
      resolve(window.supportsWebP);
      return;
    }

    const img = new Image();
    img.onload = () => {
      window.supportsWebP = true;
      resolve(true);
    };
    img.onerror = () => {
      window.supportsWebP = false;
      resolve(false);
    };
    // Small WebP image data URI
    img.src =
      'data:image/webp;base64,UklGRiIAAABXRUJQVlA4TA0AAAAvAAAAAAfQ//73v/+BiOh/AAA=';
  });

  return window.webpSupportPromise;
}
</script>
